.team-row--light {
    background-color: $color-light;
    padding: 0.75rem 0 1rem 0;
    &-alt {
        background-color: rgba($color-secondary, 0.1);
        padding: 1.625rem 0;
    }
}
.team-row--alt {    
    padding: 4rem 0;
    @media(max-width: $desktop) {
        padding: 2rem 0;
    }
    
}
.team {    
    overflow: hidden;
}
.team-list {    
    margin: -2.25rem;
    display: flex;
    flex-wrap: wrap;
    &--big {
        justify-content: center;
    }
    &--company {
        margin: -1rem;        
    }
    &--company-with-info {        
        justify-content: space-between;
        margin: -3rem -1rem;
    }
    &--alt {
        margin: -2rem;
    }
    &__item {        
        padding: 2.25rem;        
        width: 25%;       
        .team-list--alt & {
            padding: 2rem;
        }
        .team-list--company & {
            padding: 1rem;            
        }        
        .team-list--company-with-info & {
            width: 50%;
            border-top: 1px solid lighten($color-grey, 15%);            
            padding: 3rem 1rem;
            &:nth-child(1),
            &:nth-child(2) {
                border-top-width: 0;
            }
        }
        .team-list--big & {
            width: 40%;
        }
    }
    @media(max-width: $desktop) {
        &--alt {
            margin: -1rem;
        }
        .team-list--alt &__item {
            padding: 1rem;            
        }
    }
    @media(max-width: $tablet-h) {
        margin: -1.75rem;
        &--alt {
            margin: -1rem;
        }
        &--company {
            margin: -1rem;        
        }
        &__item {
            width: 33.33%;
            padding: 1.75rem;            
            .team-row &:last-child {                
                display: none;
            }
            .team-list--big &:last-child,
            .team-list--alt &:last-child {
                display: block;
            }
        }
    }
    @media(max-width: $tablet-v) {
        .team-list--company-with-info &__item {
            width: 100%;
            &:nth-child(2) {
                border-top-width: 1px;
            }
        }
        .team-list--big &__item {
            width: 45%;
        }
    }
    @media(max-width: $phone-h) {
        margin: -1.25rem;
        &--alt {
            margin: -1rem -0.5rem;
        }
        &--company {
            margin: -0.625rem;
        }
        &__item {
            width: 50%;
            padding: 1.25rem;
            .team-row &:last-child {                
                display: block;
            }
            .team-list--company & {
                padding: 0.625rem;
            }
            .team-list--company-with-info & {
                padding: 2rem 0.625rem;
            }
        }
        .team-list--big &__item {
            width: 50%;
        }        
    }
    @media(max-width: $phone-s) {
        margin: -1.25rem 0;
        &--alt {
            margin: -1rem;
        }
        &__item,
        .team-list--big &__item {            
            width: 100%;            
        }
    }
}
.team-member {
    display: block;
    text-decoration: none;
    position: relative;
    padding: 0.25rem 0.75rem;
    max-width: 264px;
    height: 100%;
	&--logo {	
		padding: 1rem;
		min-height: 240px;
		height: 100%;
		display: flex;
		align-items: center;
		background-color: $color-white;
        margin: 0 auto;
		&:after {
			display: none;
		}
	}
    @media(max-width: $desktop) {
        margin: 0 auto;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0.25rem;
        bottom: 0.25rem;
        left: 0.75rem;
        right: 0.75rem;
        background: $color-light url('../images/icons/icon-member.svg') no-repeat center center;
    }
    .team-list--big &:after {
        left: 1rem;
        right: 1rem;
    }
    &__image-box {        
        width: 100%;
    }
    &__image {      
        display: block;
        /*width: 100%;*/        
        /*max-width: 232px;*/
		/*max-width: 100%;*/
        height: auto;
        position: relative;
        z-index: 1;
		max-height: 320px;
		margin: 0 auto;
        .team-list--big & {
            max-height: none;
        }
    }
    &__info {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        padding: 0.5rem 1rem;
        min-width: 10.5rem;
        border-radius: 2px;
        background-color: $color-primary;
        font-family: $font-akrobat;
        font-size: 1.0625rem;
        font-weight: 700;
        .team-list--company & {
            min-width: 0;
        }
        &--alt {
            background-color: $color-secondary;
        }        
        &-name {
            display: block;                   
            color: $color-white;
            margin-bottom: 0.25rem;
            line-height: 1;
            .team-list--company & {
                margin-bottom: 0;
            }
        }
        &-company {
            display: block;
            font-size: 0.9375rem;
            color: rgba($color-white, 0.7);
            letter-spacing: 0.3px;
            text-transform: uppercase;  
            line-height: 1;            
        }
    }
    @media(min-width: $phone-h) {
        .team-list--big & {                            
            max-width: 362px;
            margin: 0 auto;
            padding: 0.25rem 1rem;
        }
        .team-member__info--big {
            padding: 0.75rem 1.375rem;
            min-width: 14rem;
        }   
        .team-member__info--big .team-member__info-name {
            font-size: 1.4375rem;
            letter-spacing: 0.34px;
            margin-bottom: 0.375rem;
        }
        .team-member__info--big .team-member__info-company {
            font-size: 1.3125rem;
            letter-spacing: 0.3;
        }
    }
}
.company-member {    
    width: 100%;    
    display: block;
    text-decoration: none;
    position: relative;
    .team-list--company-with-info & {        
        display: flex;
        align-items: flex-start;
        @media(max-width: $phone-v) {
            flex-wrap: wrap;
        }
    }
    .non-touch &:hover &__logo {
        background-color: rgba($color-light, 0.5);
    }
    .non-touch &:hover &__info-title {
        color: $color-primary;
    }
    &__logo {        
        display: flex;
        align-items: center;
        justify-content: center;        
        height: 14rem;
        padding: 1.375rem;
        background-color: $color-light;
        transition: background-color $transition;         
        &--alt {
            background-color: $color-white;
            border: 1px solid $color-primary;
        }
        &-image {        
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
        .team-list--company-with-info & {
            width: 46%;
            flex-shrink: 0;            
            @media(max-width: $phone-v) {
                width: 100%;
                margin-bottom: 1.5rem;
            }
        }
        @media(max-width: $desktop) {
            height: 12rem;
        }    
        @media(max-width: $tablet-v) {
            height: 10rem;
        }
        @media(max-width: $phone-h) {
            height: 8rem;
        }
    }    
    &__info {
        flex-shrink: 1;
        padding: 2rem 3rem 2rem 1.5rem;
        @media(max-width: $tablet-h) {
            padding: 2rem 1.5rem;
        }
        @media(max-width: $tablet-v) {
            padding: 0 0 0 2rem;
        }
        @media(max-width: $phone-v) {
            padding: 0;
        }
        &-title {
            display: block;
            font-family: $font-akrobat;
            font-size: 1.875rem;
            line-height: 1;
            font-weight: 700;
            letter-spacing: 0.6px;
            color: $color-accent;
            margin-bottom: 0.875rem;
            transition: color $transition-fast;
        }
        &-text {            
            font-size: 0.9375rem;
            &.text li,
            &.text p {
                line-height: 1.46;
                font-weight: 400;
            }
        }
    }
}
.member-pic {
    display: block;
    margin: 0 auto;
}
.member-header {    
    margin-bottom: 1.75rem;
    padding-bottom: 1.5rem;
    position: relative;
    width: 100%;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 3.125rem;
        height: 3px;
        background-color: $color-accent;
    }
    &__title {
        font-family: $font-akrobat;
        font-size: 2rem;
        font-weight: 700;
        color: $color-accent;
        margin: 0 0 0.5rem 0;
    }
    &__subtitle {
        font-family: $font-akrobat;
        font-size: 1.5rem;
        font-weight: 700;
        color: $color-primary;
        margin: 0;
        &--small {
            font-size: 1.125rem;
        }
    }
}
.member-text {
    font-size: 1rem;
    &.text p,
    &.text li {
        font-weight: 400;
    }
}
/* link list */
.link-list {    
    display: flex;
    flex-wrap: wrap;
    &--padded-top {
        padding-top: 2rem;
    }
    &__title {
        display: inline-block;
        font-weight: 700;
        font-size: 1.125rem;
        color: $color-black;
        margin-right: 1.5rem;
    }
    &__item {        
        display: flex;
        align-items: center;
        &:after {
            content: '';
            width: 1px;
            height: 1.5rem;
            background-color: rgba($color-black, 0.8);
            margin: 0 1rem;            
        }
        &--title:after,
        &:last-child:after {
            display: none;
        }
        @media(max-width: $phone-v) {
            width: 100%;
            margin-bottom: 0.5rem;
            &:last-child {
                margin-bottom: 0;
            }
            &:after {
                display: none;
            }
        }
    }
    &__link {
        display: inline-block;
        font-size: 1.125rem;
        line-height: 1.33;
        color: $color-accent;
        transition: color $transition;
        .non-touch &:hover {
            color: $color-primary;
        }
    }
}
/* end of link list */
