/* navigation */
.main-nav {
    max-width: 43rem;
    overflow: hidden;
    margin: 1.125rem 0 1.125rem 1.125rem;            
    width: 100%;
    opacity: 0;
    /* fade in after init */
    transition: all $transition 0.3s;
    @media(max-width: $tablet-h) {
        order: 1;
        flex-grow: 0;
        margin-left: 0;
    }
    .priority-nav-ready & {       
        opacity: 1;    
        overflow: visible;
    }
    &__list {
        display: flex;    
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: flex-end;
        flex-wrap: nowrap;
        background-color: $color-accent;    
    }
    &__list-item {
        text-align: center;    
    }
    &__link {
        display: block;    
        text-decoration: none;            
        text-align: left;
                
        color: $color-white;
        font-family: $font-akrobat;
        font-size: 1.25rem;
        line-height: 1.3;
        padding: 1rem 1.5625rem;
        white-space: nowrap;
        transition: background-color $transition;
        .non-touch &:hover {
            background-color: darken($color-accent, 2.5%);
        }
        &.is-active {
            background-color: darken($color-accent, 7.5%);
        }
        .nav__dropdown &.is-active {
            background-color: darken($color-accent, 12.5%);            
        }
    }
    &__dropdown-link {
        display: block;
        text-decoration: none;
        text-align: left;    
    }
}
/* end of navigation */

/* priority nav plugin */
.priority-nav {
    white-space: nowrap;
    font-size: 0;
    display: flex;
    justify-content: space-between;
}
.priority-nav-has-dropdown .priority-nav__dropdown-toggle {
    display: inline-block;    
    border: 0;
    outline: none;
    vertical-align: middle;
    padding: 0;
    cursor: pointer;
    background: $color-accent;
    width: 3.625rem;
    height: 3.625rem;
    margin-left: 1.125rem;
    @media(max-width: $tablet-h) {
        margin-left: 0;
    }
    &.is-open {
        background-color: darken($color-accent, 7.5%);
    }    
    &:before {
        content: '';
        position: absolute;
        top: 1.125rem;
        left: 0.875rem;            
        width: 1.875rem;
        height: 1.4375rem;
        border-top: 3px solid $color-white;
        border-bottom: 3px solid $color-white;
        transition: transform $transition-fast;
    }
    &:after {
        content: '';
        position: absolute;
        top: 1.75rem;
        left: 0.875rem;
        width: 1.875rem;
        height: 3px;
        background-color: $color-white;
        transition: transform $transition-fast;
    }
    &.is-open:before {
        height: 3px;
        border: 0;
        background: $color-white;
        transform: rotate(-45deg) translate(-0.4375rem, 0.4375rem);
    }
    &.is-open:after {
        transform: rotate(45deg);
    }
}
.priority-nav__wrapper {
    position: relative;
    display: inline-block;
}
.priority-nav__dropdown {
    position: absolute;
    z-index: 10;
    visibility: hidden;    
    right: 0;
    top: 100%;    
    transition: all $transition;    
    opacity: 0;
    min-width: 200px;
    max-width: 100%;
    padding: 1.25rem 0;    
    background-color: darken($color-accent, 7.5%);
}
.priority-nav__dropdown.show {
    visibility: visible; 
    transform: translate(0%, 0%);
    opacity: 1;
}
.priority-nav__dropdown-toggle {
    visibility: hidden;
    /*position: absolute;*/
    position: relative;
}
.priority-nav-is-visible {
    visibility: visible;
}
.priority-nav-is-hidden {
    visibility: hidden;
}
/* end of priority nav plugin */