/* custom check radio */
.check-radio {
	display: inline-block;
	
	&__element {
		display: inline-block;
		padding-left: 1.75rem;
		position: relative;
		font-size: 1rem;
		line-height: 1.25rem;
		
		&:before,
		&:after {
			content: '';
			width: 1.25rem;
			height: 1.25rem;
			background-color: $color-white;
			border: 1px solid $color-accent;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.35s ease;
            .field--error & {
                border-color: $color-accent2;
            }
		}
	
		&:after {
			width: 0.75rem;
			height: 0.75rem;
			border: 0;
			top: 0.25rem;
			left: 0.25rem;
		}
	}

	&__input {
		display: none;
	}

	.lt-ie9 &__input {
		display: inline-block;
		vertical-align: middle;
	}

	.lt-ie9 &__element:before,
	.lt-ie9 &__element:after {
		display: none;
	}

	&__input:checked ~ &__element:after {
		background: $color-primary;
	}
	&__input:disabled ~ &__element:after {
		background: $color-black;
	}
	&__input[type="radio"] ~ &__element:before,
	&__input[type="radio"] ~ &__element:after {
		border-radius: 50%;
	}
}
/* end of custom check radio */
.select {    
    position: relative;
    border-bottom: 1px solid rgba($color-black, 0.45);
    &--min-width {
        min-width: 8.125rem;
    }    
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.625rem;
        width: 0;
        height: 0;
        border: 0.375rem solid transparent;        
        border-top-color: $color-black;
        border-bottom: 0;
    }
    select {        
        border: 0;
        width: 100%;
        color: $color-black;
        background-color: $color-white;
        font-size: 1.5rem;
        line-height: 1;
        font-family: $font-akrobat;
        font-weight: 600;
        appearance: none;
        border-radius: 0;
        padding: 0.625rem 1.875rem 0.625rem 0.625rem;
        background: transparent;        
    }
    select::-ms-expand {
        display: none;
    }
    select:focus::-ms-value {
        background-color: transparent;
        color: $color-black;        
    }
    &--white select:focus::-ms-value {
        color: $color-white;
    }
    select::-moz-focus-inner { 
        outline: none !important;
    }    
    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $color-black;
    }
    &--white select:-moz-focusring {
        text-shadow: 0 0 0 $color-white;
    }
    &--white select {
        color: $color-white;
        background-color: transparent;
        & option {
            color: $color-black;
            background-color: $color-white;
        }
    }
    &--white {
        border-bottom-color: rgba($color-white, 0.45);
    }
    &--white:after {
        border-top-color: $color-white;
    }    
}
.form {
    &--compact {
        max-width: 56.25rem;
        margin: 0 auto;
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        min-width: 100%;        
        margin: 0 -1.625rem;
        &--spaced {
            margin-bottom: 1.25rem;
        }
        @media(max-width: $phone-v) {
            margin: 0 -1.25rem;
        }
    }
}
.field {        
    padding: 0.875rem 1.625rem;
    flex-grow: 1;
    width: 33.33%;
    @media(max-width: $tablet-h) {
        width: 50%;
    }
    @media(max-width: $phone-h) {
        width: 100%;
    }
    @media(max-width: $phone-v) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    &__label {
        display: block;
        color: $color-black;
        margin-bottom: 0.625rem;
    }
    &__input {             
        width: 100%;       
        font-family: $font-roboto;
        color: $color-black;        
        border: 1px solid $color-accent;
        padding: 0 1rem;
        height: 3.125rem;
        font-size: 16px;
        appearance: none;
        border-radius: 0;
        transition: border-color $transition;
        &--textarea {
            height: auto;
            min-height: 7.5rem;
            padding: 1rem;
        }
        .field--error & {
            border-color: $color-accent2;
        }
    }    
}
.form__result {    
    text-align: center;
    margin: 2rem 0 0 0;
    color: $color-primary;
    font-size: 1.125rem;
    font-weight: 300;
    &--error {
        color: $color-accent2;
    }
}
/* errors */ 
.field__error-msg  {
    display: block;
    font-size: 0.875rem;
    color: $color-accent2;
    margin-top: 0.5rem;
}
/* google maps */
.google-maps {
    position: relative;
    width: 100%;
    padding-bottom: 35%;
    background-color: $color-light;
    &__container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    } 
    @media(max-width: $tablet-h) {
        padding-bottom: 40%;
    }
    @media(max-width: $tablet-v) {
        padding-bottom: 50%;
    }
    @media(max-width: $phone-h) {
        padding-bottom: 60%;
    }    
}