/* custom dropdown */
.custom-dropdown {
    display: inline-block;
    position: relative;    
    &__list {
        border: 1px solid darken($color-light, 5%);
        padding: 0.75rem;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        right: 0;        
        background-color: lighten($color-light, 1.5%);
        transform: translateY(0.75rem);
        opacity: 0;
        visibility: hidden;
        transition: transform $transition, opacity $transition, visibility $transition;        
        .is-open & {
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
        }
        &--bottom {
            bottom: auto;
            transform: translateY(-0.75rem);
        }               
    }    
    &__link {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        color: $color-black;        
        text-decoration: none;
        transition: color $transition; 
        &:last-child {
            margin-bottom: 0;        
        }
        .non-touch &:hover {
            color: $color-accent;
        }
        &-icon {
            width: 1.25rem;
            height: 1.25rem;
            fill: rgba($color-black, 0.75);
            margin-right: 0.5rem;
            transition: fill $transition;
        }
        .non-touch &:hover &-icon {
            fill: $color-accent;
        }
    }
}
/* end of custom dropdown */