.main-header {
    background-color: $color-accent;
    &__row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 3.125rem;        
        @media(max-width: $desktop) {
            padding: 0;
        }
    }
}
.site-logo {    
    margin: 0 auto 0 0;
    &__link {
        display: block;
        text-decoration: none;
    }
    &__image {
        display: block;
        max-width: 100%;
        height: auto;
    }
}
.lang-link {
    display: inline-block;
    font-family: $font-akrobat;
    font-size: 0.9375rem;
    font-weight: 700;
    color: $color-white;
    text-decoration: none;
    padding: 0.25rem;
    margin-left: 2.5rem;
    @media(max-width: $tablet-h)  {
        margin-right: 1rem;
        margin-left: 1rem;
    }
}

.social-header {    
    position: absolute;
    z-index: 10;
    bottom: 7.5rem;
    right: 0;        
    display: flex;
    justify-content: center;    
    &__list {
        padding: 0 1.5rem;
        &-item {
            border-bottom: 1px solid rgba($color-white, 0.5) ;
            padding: 1.25rem 0;
            &:last-child {
                border-bottom: 0;;
            }
        }
    }
    @media(max-width: 1330px) {
        display: none;
    }
}