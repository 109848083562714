.sitemap {
    font-size: 1rem;
    &__list {     
        & & {
            padding-left: 1em;
        }
        & & &-item {
            padding-left: 1em;        
            &:before {
                content: '';
                position: absolute;
                left: -0.5em;
                top: 0.625em;
                width: 1em;
                height: 1px;
                background: $color-light;            
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: -0.5em;
                bottom: 0;
                width: 1px;
                background: $color-light;
            }
            &:first-child {
                padding-top: 1em;
                &:before {
                    top: 1.625em;
                }
                &:after {
                    top: 0.625em;
                }
            }
            &:last-child {
                &:after {
                    bottom: auto;
                    height: 0.625em;
                }
            }
        }    
        &-item {  
            padding-bottom: 1em;                      
            position: relative;
            &:last-child {
                padding-bottom: 0;
            }
        }
        &-link {
            display: inline-block;        
            font-size: 1em;
            line-height: 1;
            color: $color-black;
            text-decoration: none;
            transition: color $transition;
            &--large {
                font-weight: 400;
                font-size: 1.125em;
            }
            &--small {                
                font-size: 0.875em;
                color: rgba($color-black, 0.8);
            }
            .non-touch &:hover {
                color: $color-accent;
            }
        }
    }    
}