.timeline {    
    margin: 0 auto;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 100%;
        background-color: $color-accent;
        @media(max-width: $phone-h) {
            left: 2rem;
        }
    }
    &__item {        
        text-align: center;
        padding-bottom: 5.25rem;        
        @media(max-width: $phone-h) {
            text-align: left;
            padding-bottom: 3rem;
        }        
    }
    &__year {
        display: inline-block;
        padding: 1.25rem 2rem;
        font-size: 1.875rem;
        font-family: $font-akrobat;
        line-height: 1;
        color: $color-white;
        background-color: $color-primary;
        margin-bottom: 2.75rem;
        position: relative;
        z-index: 1;
        @media(max-width: $phone-h) {
            margin-bottom: 1.5rem;
            margin-left: calc(2rem - 1px);
        }
    }
    &__image {        
        width: 100%;
        height: auto;
        max-width: 400px;
    }
    &-container {        
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-left: calc(constant(safe-area-inset-left) + 2rem);
        padding-left: calc(env(safe-area-inset-left) + 2rem);
        padding-right: calc(constant(safe-area-inset-right) + 2rem);
        padding-right: calc(env(safe-area-inset-right) + 2rem);
        .timeline__item:nth-child(even) & {
            background-color: $color-light;
            flex-direction: row-reverse;
        }
        &__image {
            padding-right: 5.625rem;
            width: 50%;
            position: relative;
            max-width: 490px;
            &:after {
                content: '';
                position: absolute;
                z-index: 2;
                top: 50%;
                left: 100%;
                margin-top: -0.5rem;
                margin-left: -0.5rem;
                width: 1rem;
                height: 1rem;            
                background-color: $color-accent;
                border-radius: 50%;
            }
            .timeline__item:nth-child(even) & {
                transform: translateY(1.25rem);
                padding-right: 0;
                padding-left: 5.625rem;
                align-self: flex-end;
                &:after {
                    left: 0;
                }
            }
        }
        &__text {
            padding-left: 5.625rem;
            padding-top: 2rem;
            padding-bottom: 2rem;            
            width: 50%;            
            text-align: left;
            max-width: 490px;            
            .timeline__item:nth-child(even) & {
                padding-left: 0;
                padding-right: 5.625rem;
            }
        }
        &__title {
            display: block;
            font-size: 1.875rem;            
            margin: 0 0 1rem 0;
            font-weight: 400;
        }
        
        @media(max-width: $tablet-h) {
            &__title {
                font-size: 1.5rem;                
            }
            &__image {
                padding-right: 3.5rem;
                .timeline__item:nth-child(even) & {
                    transform: translateY(1.25rem);
                    padding-right: 0;
                    padding-left: 3.5rem;
                }
            }
            &__text {
                padding-left: 3.5rem;
                .timeline__item:nth-child(even) & {
                    padding-left: 0;
                    padding-right: 3.5rem;
                }
            }
        }
        @media(max-width: $phone-h) {
            .timeline__item:nth-child(even) & {
                padding: 2rem;
                justify-content: flex-end;
            }
            & {
                justify-content: flex-start;
            }
            
            &__image {
                margin-bottom: 2rem; 
                .timeline__item:nth-child(even) & {
                    transform: translateY(0);
                }
                &:after {
                    left: 0;
                    top: calc(100% + 1rem);
                }                
            }
            &__image,
            &__text {
                padding: 0 0 0 4rem;
                width: 100%;
                .timeline__item:nth-child(even) & {
                     padding: 0 0 0 4rem;
                }
            }
        }
    }
}
/* timeline president slider */
.timeline-president-slider {    
    margin-bottom: 5rem;
    @media(max-width: $tablet-h) {
        margin-bottom: 3.5rem;
    }
    @media(max-width: $tablet-v) {
        margin-bottom: 2rem;
    }
    &__item {        
        &:not(:first-child) {            
        	opacity: 0;
        	position: absolute;
        }
        .slick-initialized &:not(:first-child) {
        	opacity: 1;
        	position: static;
        }
    }
}
/* timeline years slider */
.timeline-years-slider {    
    overflow: hidden;
    padding: 0 5rem;
    position: relative;
    white-space: nowrap;
    font-size: 0;
    &__item {
        display: inline-block;
        text-align: center;
        position: relative;        
        padding: 0.25rem 1.25rem 0 1.25rem;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0.5rem;
            margin-bottom: -1px;
            height: 2px;
            background-color: $color-accent;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -0.5rem;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background-color: $color-accent;
            transform: scale(0,0);
            transition: transform $transition;
            .slick-current & {
                transform: scale(1,1);
            }
        }
    }
    &__time {
        display: inline-block;
        font-family: $font-akrobat;        
        font-size: 1.875rem;
        line-height: 2rem;
        margin: 0 auto 1rem auto;
        transition: $transition;
        .slick-current & {
            /*font-size: 2.125rem;*/            
            color: $color-accent;
            font-weight: 600;
            transform: translateY(-0.25rem) scale(1.1);
        }
    }
    &__btn {
        width: 3rem;
        height: 3rem;    
        padding: 0;
        position: absolute;
        z-index: 1;
        top: 50%;
        margin-top: -1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        &:after {
            content: '';
            display: block;        
            width: 1.75rem;
            height: 1.75rem;
            border-top: 2px solid $color-primary;
            border-right: 2px solid $color-primary;
            transition: border-color $transition-fast;
        }
        &--prev {        
            left: 1rem;
            &:after {
                transform: rotate(-135deg) translate(-0.25rem, 0.25rem);
            }
        }
        &--next {        
            right: 1rem;
            &:after {            
                transform: rotate(45deg) translate(-0.25rem, 0.25rem);
            }
        }
        .non-touch &:hover:after {
            border-color: $color-accent;
        } 
    }
}