.cover {
    background-color: $color-accent;
    position: relative;
    z-index: 1;
    margin-bottom: 5rem;
    @media(max-width: $tablet-h) {
        margin-bottom: 4rem;
    }
    @media(max-width: $phone-h) {
        margin-bottom: 2.75rem;
    }
    &--negative {
        background-color: transparent;
        margin-bottom: 3.5rem;
        &:before {
            content: '';
            background-color: $color-accent;;
            position: absolute;
            top: 0;
            bottom: 3.5rem;
            left: 0;
            right: 0;
        }
    }
    &__slider {
        max-width: 1200px;    
        margin: 0 auto 3rem auto;
        & .slick-dots {            
            position: absolute;
            z-index: 1;
            bottom: 2.625rem;
            padding: 0 3.625rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media(max-width: $tablet-v) {
                bottom: 0rem;
                padding: 1rem;
            }
            button {
                display: block;
                background: transparent;
                font-size: 0;
                width: 3.625rem;
                height: 2rem;
                padding: 0;
                margin: 0 0.5rem 0 0;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    height: 2px;
                    width: 100%;
                    left: 0;
                    background-color: $color-grey;
                    transition: background-color $transition-fast;
                }
            }
            li:not(.slick-active):hover button:before {
                background-color: lighten($color-grey, 10%);
            }
            .slick-active button:before {
                background-color: $color-accent;
            }
        }
        .cover-box-textbox {
            padding-bottom: 6rem;
            @media(max-width: $tablet-v) {
                padding-bottom: 4rem;
            }
        }
    }
    &-box {
        max-width: 1200px;
        margin: 0 auto;        
        background-size: cover;
        background-position: center top;
        background-color: darken($color-accent, 5%);
        position: relative;
        &:not(:first-child) {            
        	opacity: 0;
        	position: absolute;
        }
        .slick-initialized &:not(:first-child) {
        	opacity: 1;
        	position: static;
        }
        &-textbox {            
            &--white {
                color: $color-white;
                background-image: linear-gradient(to right , rgba(0,0,0,0.6) 0%, transparent 100%);
            }
            background-image: linear-gradient(to right , rgba(255,255,255,0.6) 0%, transparent 100%);
            min-height: 31.25rem;
            /* IE 10, 11 fix */
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 31.25rem;
            }
            padding: 2rem 0rem 2rem 3.625rem;
            width: 40%;            
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;            
            color: $color-black;            
            &-title {
                display: block;
                width: 100%;
                font-size: 2rem;
                color: currentColor;                
                font-family: $font-akrobat;
                font-size: 3.5rem;
                font-weight: 700;
                line-height: 1.07;
                text-transform: uppercase;
                margin: 0 0 0.875rem 0;            
            }
            &-subtitle {
                display: block;
                width: 100%;
                font-size: 1.5rem;
                color: currentColor;
                font-family: $font-akrobat;
                font-weight: 400;
                margin: 0 0 2rem 0;
            }
            &-text {
                width: 100%;
                display: block;
                font-size: 1.0625rem;
                line-height: 1.3;
                color: currentColor;
                margin: 0;
            }
            & .btn {
                margin-top: 1.5rem;
            }
            & &-subtitle + .btn {
                margin-top: 0;
            }
            @media(max-width: $tablet-h) {
                width: 50%;
                &-title {
                    font-size: 3rem;
                }
                &-subtitle {
                    font-size: 1.25rem;
                }
            }
            @media(max-width: $tablet-v) {
                width: 100%;
                padding: 1rem;
                justify-content: flex-end;
                background-image: linear-gradient(to top , rgba(255,255,255,0.6) 0%, transparent 100%);
                &--white {
                    background-image: linear-gradient(to top , rgba(0,0,0,0.6) 0%, transparent 100%);
                }
                & .btn {
                    margin-top: 1rem;
                }
                &-title {
                    font-size: 2.5rem;
                }
                
                &-text {
                    font-size: 1rem;                    
                }
            }
            @media(max-width: $phone-h) {
                &-title {
                    font-size: 2rem;
                }
                &-subtitle {
                    font-size: 1.125rem;
                }
            }
        }
    }    
}