.media-block {
    background-color: $color-accent;
    display: flex;    
    align-items: flex-end;
    padding: 0 5rem;
    &--align-center {
        align-items: center;
    }
    &--type-2 {
        background-color: $color-white;
        padding: 0;        
        align-items: center;
        & .btn-row {
            justify-content: flex-end;
        }
        &.media-block--reverse .btn-row {
            justify-content: flex-start;
        }
    }
    &--type-3 {
        background-color: transparent;
        padding: 0;
        align-items: center;
    }
    &--reverse {
        flex-direction: row-reverse;
    }    
    &__col {        
        width: 50%;
        &--text {
            padding: 3.125rem 3.75rem 4.25rem 1.875rem;
            .media-block--reverse & {
                padding: 3.125rem 1.875rem 4.25rem 3.75rem;
            } 
            .media-block--type-2 & {
                padding: 0 1.875rem;
                text-align: right;                
            }            
            .media-block--type-2.media-block--reverse & {
                text-align: left;
                padding: 0 1.875rem;
            }
            .media-block--type-3 & {                
                padding: 3rem  1.5rem;
            }
            @media(max-width: $tablet-h) {
                padding-left: 0;
                .media-block--reverse & {
                    padding-right: 0;
                }
                .media-block--type-3.media-block--reverse & {
                    padding: 3rem 1.5rem;
                }
            }        
        }
        .media-block--type-3-alt & {
            width: 46%;            
        }
        &--image {
            margin-top: 3.125rem;
            margin-bottom: -3.125rem;
            img {
                width: 100%;                
                max-width: 540px;
            }
            &-up {
                margin-top: -3.125rem;
                margin-bottom: 0;
            }
            &-align {
                margin-top: 0;
                margin-bottom: 0;
            }
            .media-block--type-2.media-block--reverse &,
            .media-block--type-3.media-block--reverse & {
                text-align: right;
            }
            img.media-block__portrait {                
                max-width: 21.25rem;                
                margin: 0 auto;
                display: block;                
            }
        }
    }
    &__text {
        margin-bottom: 2.125rem;
        max-width: 23.75rem;        
        &--wide {
            max-width: none;
            margin-right: -1rem;
        }
        .media-block--align-center & {
            margin-bottom: 0;
        }
        .media-block--type-2 & {
            max-width: none;
            margin-bottom: 4.25rem;
        }
        .media-block--type-3 & {
            max-width: none;
            margin-bottom: 0;            
        }
        &.text p,
        &.text li {
            line-height: 1.45;
        }        
    }
    &__title {
        font-family: $font-akrobat;
        font-size: 2.5rem;
        font-weight: 400;
        line-height: 1.05;
        color: $color-white;
        margin: 0 0 1.25rem 0;        
    }
    &--type-2 &__title,
    &--type-3 &__title {
        color: $color-black;
        font-weight: 600;
    }    
    @media(max-width: $tablet-h) {
        padding: 0 3rem;
        &--type-2,
        &--type-3 {
            padding: 0;
        }
        &__text--wide {
            margin-right: 0;
        }
        &--type-3-alt &__col {
            width: 100%;
        }
    }
    @media(max-width: $tablet-v) {
        flex-wrap: wrap-reverse;
        padding: 0 6.5rem;
        &--type-2,
        &--reverse-wrap,
        &--type-3 {
            flex-wrap: wrap;
        }
        &--type-2 .btn-row {
            justify-content: flex-start;
        }
        &__col {
            width: 100%;            
            &--text,
            .media-block--reverse &--text {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 2rem;
                padding-top: 2rem;
            }
            .media-block--type-2 &--text,
            .media-block--type-2.media-block--reverse &--text {
                padding: 2rem 0 0 0;
                text-align: left;
            }                        
            .media-block--type-3 &--text,
            .media-block--type-3.media-block--reverse &--text, {
                padding: 2rem 0;
            }            
            &--image {
                margin-top: -2rem;
                margin-bottom: 0;
                .media-block--type-2 &,
                .media-block--reverse-wrap &,
                .media-block--type-3 & {
                    margin-top: 0;
                }                
            }
        }
        &__text {
            max-width: none;            
            .media-block--type-2 & {
                margin-bottom: 2rem;
            }
        }
    }
    @media(max-width: $phone-h) {
        padding: 0 2rem;        
        &__title {
            font-size: 2rem;
        }
        &__col--image {            
            &-up,
            &-align {
                margin-bottom: 0;
            }            
        }        
    }
    @media(max-width: $phone-v) {
        &--type-2,
        &--type-3 {
            padding: 0;
        }
        .media-block--type-3-alt &__col--text,
        .media-block--type-3-alt.media-block--reverse &__col--text, {
            padding: 2rem 1.25rem;
        }
    }
}
.media-block-background {
    position: relative;    
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 1.5rem;
        bottom: 1.5rem;
        left: 0;
        right: 0;
        background: $color-light;
        @media(max-width: $tablet-v) {
            bottom: 0;
        }
    }
    &--alt:before {
        background-color: rgba($color-secondary, 0.1);
    }
    &--accent {
        &:before {            
            background-color: $color-accent;;
            top: 0;
            bottom: 3.125rem;
            @media(max-width: $tablet-v) {
                bottom: 0;
                top: 2rem;
            }
        }
        .media-block {
            background: transparent;
        }
    }
}

.media-block-slider {
    position: relative;    
    &__item {        
        padding-bottom: 3.125rem;
        &:not(:first-child) {            
        	opacity: 0;
        	position: absolute;
        }
        .slick-initialized &:not(:first-child) {
        	opacity: 1;
        	position: static;
        }
        @media(max-width: $tablet-v) {            
            padding-top: 2rem;
        }
    }
    &-nav {
        position: absolute;
        bottom: 5rem;
        left: 8.25rem;
        display: flex;
        align-items: center;
        @media(max-width: $tablet-h) {
            left: 4.25rem;
            bottom: 4rem;
        }
        @media(max-width: $tablet-v) {
            left: 50%;
            transform: translateX(-50%);
            bottom: 1.5rem;
        }
        &__btn {
            width: 2.5rem;
            background-color: transparent;
            padding: 0;
            height: 2.5rem;            
            display: flex;
            justify-content: center;
            align-items: center;
            &:after {                
                content: '';
                display: inline-block;
                width: 1.25rem;
                height: 1.25rem;
                border-top: 2px solid $color-primary;
                border-right: 2px solid $color-primary;
                transition: border-color $transition-fast;
            }
            &--next:after {
                transform: rotate(45deg);
                margin-left: -0.5rem;
            }
            &--prev:after {
                transform: rotate(-135deg);
                margin-left: 0.5rem;
            }
            .non-touch &:hover:after {
                border-color: rgba($color-white, 0.8);
            }
        }
        &__info {
            display: inline-block;
            color: $color-white;
            font-family: $font-akrobat;
            font-size: 1.875rem; 
            min-width: 0.875rem;
            text-align: center;
        }
    }
}
