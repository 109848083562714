/* news row */
.news-row {    
    display: flex;
    flex-wrap: wrap;
    margin: -0.625rem;    
    &__item {
        flex-basis: 20rem;
        flex-grow: 1;
        padding: 0.625rem;        
    }
    &__box {
        background-color: $color-light;
        padding: 2.5rem 1.375rem;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        height: 100%;
        &-title {
            display: block;
            width: 100%;
            text-decoration: none;
            font-family: $font-akrobat;
            color: $color-black;
            letter-spacing: 0.64px;
            font-size: 2rem;
            line-height: 1.25;
            margin-bottom: 1.5rem;
            transition: color $transition-fast;
            .non-touch &:hover {
                color: $color-primary;
            }
        }
        &-time {
            display: block;
            width: 100%;
            font-family: $font-akrobat;
            font-size: 1.125rem;       
            font-weight: 700; 
            letter-spacing: 0.36px;
            margin-bottom: 2.25rem;
            color: rgba($color-black, 0.7);
        }
        .btn {
            order: 1;
            margin-top: auto;
            .non-touch &:hover + .news-row__box-title {
                color: $color-primary;
            }
        }
    }    
}
/* end of news row */
/* news list */
.news-list {
    overflow: hidden;
    padding: 1.25rem 7rem 0 7rem;
    @media(max-width: $desktop) {
        padding: 0 4rem;
    }
    @media(max-width: $tablet-h) {
        padding: 0;
    }
    &__item {
        border-bottom: 1px solid $color-grey;
        padding: 3.125rem 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
}
/* end of news list */
/* news item */
.news-item {
    text-decoration: none;
    display: flex;    
    margin: -1.875rem;
    @media(max-width: $tablet-v) {
        margin: -1rem;
    }
    @media(max-width: $phone-h) {
        flex-wrap: wrap;
    }
    &__box {
        flex-shrink: 1;
        flex-grow: 1;
        padding: 1.875rem;
        &--pic {
            flex-shrink: 0;
            flex-grow: 0;            
            width: 29.75rem;
            transition: opacity $transition-fast;
            @media(max-width: $tablet-h) {
                width: 24rem;
            }            
        }
        @media(max-width: $tablet-v) {
            width: 50%;
            padding: 1rem;
        }
        @media(max-width: $phone-h) {
            width: 100%;            
            &--pic {
                flex-grow: 0;
                /*width: auto;*/
                max-width: 100%;
            }
        }
        &-title {
            display: block;
            font-size: 2rem;
            font-family: $font-akrobat;
            font-weight: 600;
            color: $color-black;            
            margin: -0.375rem 0 1.125rem 0;
            transition: color $transition-fast;
        }
        &-time,
        &-title-2 {
            display: block;
            font-family: $font-akrobat;
            font-size: 1.125rem;
            line-height: 1;
            font-weight: 700;
            color: $color-black;
            letter-spacing: 0.36px;
            margin-bottom: 1.125rem;
        }
        &-text {
            margin-bottom: 1.125rem;
        }
    }
    &__pic {
        display: block;        
        width: 100%;
        height: auto;
        margin: 0 auto;
        &--2 {
            max-width: 21.25rem;
        }
    }
    .non-touch &:hover &__box-title {
        color: $color-primary;
    }
    .non-touch &:hover .btn:after {
        transform: translateY(0%);
        opacity: 1;        
    }
    .non-touch &:hover .btn {
        color: $color-white;
    }
    .non-touch &:hover &__box--pic {
        opacity: 0.9;
    }
}
/* end of news item */