.stats {    
    display: flex;        
    margin: -1rem;
    &--padded-top {
        padding-top: 1rem;
    }
    &__item { 
        width: 25%;
        flex-grow: 1;
        padding: 1rem;
        position: relative;                
        display: flex;
        flex-wrap: wrap; 
        justify-content: center;       
        &:after {
            content: '';
            position: absolute;
            top: 1rem;
            right: 0;
            width: 1px;
            height: 5.375rem;
            background-color: lighten($color-grey, 10%);
        }        
        &:last-child:after {
            display: none;
        }        
    }
    @media(max-width: $tablet-h) {
        flex-wrap: wrap;
        &__item {
            width: 50%;
            &:nth-child(even):after {
                display: none;
            }
        }
    }
    @media(max-width: $phone-v) {
        margin: -1rem 0;
        &__item {
            width: 100%;
            margin-bottom: 0.5rem;
            &:after,
            &:nth-child(even):after {
                display: block;
                bottom: 0;
                top: auto;
                left: 20%;
                right: 20%;
                height: 1px;
                width: auto;
            }            
            &:last-child {
                margin-bottom: 0;
                &:after {
                    display: none;
                }
            }
        }
    }
}
.stats__box {    
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-number {
        display: block;
        font-family: $font-akrobat;
        font-size: 7.5rem;
        line-height: 0.75;        
        font-weight: 300;
        margin-bottom: 0.5rem;
        color: $color-primary;
        width: 100%;
        text-align: center;
        &--secondary {
            color: $color-secondary;
        }
        &--accent {
            color: $color-accent;
        }
        &--accent-2 {
            color: $color-accent2;
        }
        &--accent-3 {
            color: $color-accent3;
        }
        @media(max-width: $tablet-h) {
            font-size: 6rem;
        }
        @media(max-width: $phone-h) {
            font-size: 3.5rem;
        }
    }
    &-text {                
        font-size: 1.125rem;
        font-weight: 400;        
        line-height: 1.33;
        max-width: 14rem;
        margin: 0; 
        text-align: center;        
    }
}