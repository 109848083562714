.dual-slider {
    display: flex;
    min-width: 0;
    min-height: 0;
    position: relative;
    @media(max-width: $tablet-v) {
        margin: 0 -1rem;
    }
    @media(max-width: $phone-h) {
        flex-wrap: wrap;
        margin: 0;
    }
    &__slide {
        &:not(:first-child) {            
        	opacity: 0;
        	position: absolute;
        }
        .slick-initialized &:not(:first-child) {
        	opacity: 1;
        	position: static;
        }
        &-image-box {            
            max-width: 375px;
            margin: 0 auto;
        }
        &-image { 
            height: 0;
            padding-top: 133.33%;            
            margin: 0 auto;
            width: 100%;
            background-size: cover;
            background-position: center center;
            @media(max-width: $phone-h) {
                background-image: none!important;
            }
        }
    }
    &__half {        
        width: 50%;
        padding: 0 6.875rem;
        &--with-nav {
            padding-bottom: 5rem;
            padding-top: 1rem;            
        }
        @media(max-width: $tablet-h) {
            padding-left: 3.75rem;
            padding-right: 3.75rem;
        }
        @media(max-width: $tablet-v) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @media(max-width: $phone-h) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            &--with-nav {
                padding-top: 0;
            }
        }
        &-slider {            
            img {
                margin: 0 auto;                
            }
        }
        &--image {
            @media(max-width: $phone-h) {
                display: none;
            }
        }
    }    
   
    &__icon {        
        width: 10rem;           
        position: absolute;        
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        fill:  $color-light;
        @media(max-width: $tablet-h) {
            width: 6rem;
        }
        @media(max-width: $tablet-v) {
            display: none;
        }
    }
    &__nav {        
        display: flex;
        position: absolute;
        bottom: 0;
        &-btn {
            width: 3rem;
            height: 3rem;
            padding: 0;            
            position: relative;
            background-color: transparent;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -0.5rem 0 0 -0.25rem;
                width: 1rem;
                height: 1rem;
                border-top: 2px solid $color-grey;
                border-right: 2px solid $color-grey;
                transition: border-color $transition;
                transform: rotate(45deg);                
            }
            &--prev:after {
                transform: rotate(-135deg);                
            }
            &--next:after {                
                margin-left: -0.75rem;
            }
            .non-touch &:hover:after {
                border-color: $color-accent;
            }
        }
    }
    &__label {
        display: block;
        font-family: $font-akrobat;
        font-size: 1.25rem;
        font-weight: 700;
        color: $color-accent;
        margin-bottom: 1.25rem;
    }
    &__title {
        display: block;
        font-family: $font-akrobat;
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 1.05;
        color: $color-black;
        margin-bottom: 2.5rem;
        padding-bottom: 2rem;
        position: relative;
        &:after {
            content: '';
            width: 3.125rem;
            height: 3px;
            background-color: $color-accent;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        @media(max-width: $tablet-h) {
            font-size: 2rem;
            padding-bottom: 1.75rem;
            margin-bottom: 2rem;
        }
        @media(max-width: $phone-h) {
            font-size: 1.75rem;
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
    &__text {
        color: $color-black;
        margin-bottom: 2.5rem;
        @media(max-width: $tablet-h) {
            margin-bottom: 2rem;
        }
        @media(max-width: $phone-h) {
            margin-bottom: 1.5rem;
        }
    }    
}