.main-footer {
    background-color: #373e46;    
    padding: 3.25rem 0 0 0;
    @media(max-width: $phone-v) {
        padding-top: 2.25rem;
    }
}
.main-footer__layout {    
    display: flex;    
    @media(max-width: $tablet-h) {
        flex-wrap: wrap-reverse;
    }
}
.footer-col {    
    flex-grow: 1;
    flex-shrink: 0;
    width: 20.625rem;
    padding-bottom: 3rem;
    padding-right: 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    @media(max-width: $desktop) {
        flex-shrink: 1;
    }
    @media(max-width: $tablet-h) {
        flex-direction: row;
        align-items: center;        
        justify-content: space-between;
        padding-right: 0;
    }
    @media(max-width: $phone-h) {
        padding-bottom: 2rem;
        align-items: flex-start;
    }
    &__logo {
        display: block;        
        width: 8.125rem;        
        margin-bottom: 2.25rem;
        @media(max-width: $tablet-h) {
            margin-bottom: 0;
            margin-right: 1rem;
        }
        @media(max-width: $phone-h) {
            margin-bottom: 1rem;
        }
    }
    & .btn {        
        margin-bottom: 2rem;
        @media(max-width: $tablet-h) {
            margin-bottom: 0;
        }
    }
}
.footer__social {    
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: -0.25rem;
    @media(max-width: $tablet-h) {
        margin-top: 0;
    }
    @media(max-width: $phone-h) {        
        width: 100%;
        margin-top: 1rem;
    }
    &-text {
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1;
        color: $color-white;
        margin-right: 1rem;
    }
    &-icon {
        width: 1.25rem;
        height: 1.25rem;        
        margin: 0.25rem;
    }
}
.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 1.5rem;
    height: 1.5rem;
    transition: opacity $transition-fast, transform $transition-fast;
    .non-touch &:hover {
        opacity: 0.8;
    }
    .non-touch &:active {
        opacity: 1;
        transform: translateY(1px);
    }
    &__icon {
        display: block;
        width: 1rem;
        height: 1rem;        
        fill: $color-white;
    }
}
.footer-nav {    
    padding-right: 3rem; 
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;    
    @media(max-width: $desktop) {
        padding-right: 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;        
        margin: 0 -1rem;
        &-item {            
            width: 25%;
            padding: 0 1rem;
            margin-bottom: 1rem;            
            @media(max-width: $tablet-v) {
                width: 33.33%;
            }
            @media(max-width: $phone-h) {
                width: 50%;
            }
        }        
    }
    &__link {
        display: block;        
        text-decoration: none;
        font-size: 1.125rem; 
        line-height: 1;       
        color: black;
        margin-bottom: 2rem;        
        color: $color-white;                
        transition: color $transition-fast;
        
        .non-touch &:hover {
            color: rgba($color-white, 0.85);            
        }                
        &--sub {
            font-size: 0.9375rem;
            font-weight: 400;
            color: rgba($color-white, 0.7);
            margin-bottom: 0.9375rem;
            &:last-child {
                margin-bottom: 3rem;
            }
            .non-touch &:hover {
                color: rgba($color-white, 1);            
            }
        }
        @media(max-width: $tablet-h) {
            &--sub {
                &:last-child {
                    margin-bottom: 2rem;
                }
            }
        } 
        @media(max-width: $phone-h) {
            margin-bottom: 1.25rem;
            &--sub {
                margin-bottom: 1rem;
                &:last-child {
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}

.copyright {
    background-color: #292c31;    
    padding: 1.625rem 0;
    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: rgba($color-white, 0.5);
        margin: -0.5rem;
    }
    &__text {
        font-size: 0.875rem;
        padding: 0.5rem;
        color: rgba($color-white, 0.3);
        &-link {
            color: inherit;
            transition: color $transition-fast;
            .non-touch &:hover {
                color: rgba($color-white, 0.8);
            }
        }
    }
}

/* cookie bar */
.cookie-bar {    
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    max-width: 30rem;
    padding: 1.5rem;
    padding-bottom: calc(constant(safe-area-inset-bottom) + 1.5rem);
    padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
    background-color: #fff;
    box-shadow: 0px 0px 5rem rgba(0,0,0,0.1);
    display: flex;
    align-items: flex-end;
    &__text {
        flex-shrink: 1;        
    }
    &__btn {
        margin-top: 1rem;
        text-align: right;
        margin-left: 1rem;
    }
}
/* end of cookie bar */