.pagination {    
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    border-top: 2px solid $color-grey;
    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__btn {
        display: flex;
        align-items: center;        
        text-decoration: none;        
        height: 2rem;
        position: relative;
        &:after {
            content: '';
            display: block;
            flex-shrink: 0;            
            width: 1.125rem;
            height: 1.125rem;
            border-top: 2px solid $color-black;
            border-right: 2px solid $color-black;            
            transform: rotate(45deg);
            margin: 0 0.5rem;
        }
        &--prev {
            flex-direction: row-reverse;
        }
        &--prev:after {
            transform: rotate(-135deg);            
        }
        &--disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }        
        &-text {
            display: block;
            color: $color-black;
            font-family: $font-akrobat;
            font-size: 1.25rem;
            line-height: 1;
            transition: color $transition-fast;
            @media(max-width: $phone-h) {
                display: none;
            }
        }
        .non-touch &:hover:not(&--disabled) &-text {
             color: $color-accent;
        }
        .non-touch &:hover:not(&--disabled):after {
            border-color: $color-accent;
        }
    }
    &__link {
        display: block;
        text-decoration: none;        
        font-family: $font-akrobat;
        font-size: 1.625rem;
        line-height: 1;
        font-weight: 600;
        color: $color-grey;
        padding: 0.125rem;
        text-align: center;
        min-width: 1.25rem;
        margin: 0 0.25rem;
        border-bottom: 2px solid transparent;
        transition: color $transition-fast;
        &.is-active {
            color: $color-black;
            border-bottom-color: $color-black;
        }
        .non-touch &:hover {
            color: rgba($color-black, 0.5);
        }
    }
}