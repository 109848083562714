.stories {
    position: relative;
    padding-right: 5rem;    
    @media(max-width: $phone-v) {
        padding-right: 0;
    }
    &__slider {
        &-slide {
            text-decoration: none;
            color: inherit;
            &:not(:first-child) {            
            	opacity: 0;
            	position: absolute;
            }
            .slick-initialized &:not(:first-child) {
            	opacity: 1;
            	position: static;
            }
        }
        &-container {            
            background-color: $color-white;
            display: flex;
            flex-wrap: wrap;
        }
        &-box {            
            width: 50%;
            padding: 5.75rem 5rem;            
            position: relative;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;            
            align-content: center;
            @media(max-width: $tablet-h) {
                padding: 3rem;
            }
            @media(max-width: $phone-v) {
                padding: 2rem;
            }
            &--pic {                                
                padding: 1.5rem;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;                
                &:before {
                    content: '';
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $color-primary;
                    opacity: 0.92;
                }
            }
            @media(max-width: $tablet-v) {
                width: 100%;
            }
        }        
    }
    &__nav {        
        position: absolute;
        top: 0;
        right: 0;
        @media(max-width: $phone-v) {
            position: static;
        }
        &-btn {
            display: block;
            padding: 0;
            background: $color-white;
            border: 0;
            width: 5rem;
            height: 5rem;
            position: relative;
            transition: background-color $transition;                        
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -0.25rem 0 0 -0.5rem;
                width: 1rem;
                height: 1rem;
                border-top: 2px solid $color-primary;
                border-right: 2px solid $color-primary;
                transition: border-color $transition;
            }            
            &--prev:after {                
                transform: rotate(-45deg);
            }
            &--next:after {
                margin-top: -0.5rem;
                transform: rotate(135deg);
            }
            .non-touch &:hover {
                background-color: lighten($color-primary, 3%);
            }
            .non-touch &:active {
                background-color: lighten($color-primary, 1.5%);                
            }
            .non-touch &:hover:after {
                border-color: $color-white;
            }
        }
        @media(max-width: $phone-v) {
            display: flex;
            justify-content: center;
            width: 100%;
            &-btn--prev:after {                
                transform: rotate(-135deg);
                margin-top: -0.5rem;
                margin-left: -0.25rem;
            }
            &-btn--next:after {
                transform: rotate(45deg);
                margin-top: -0.5rem;
                margin-left: -0.75rem;
            }
        }
    }
    &__title {
        width: 100%;
        font-weight: 400;
        font-size: 1.875rem;
        margin: 0 0 1rem 0;
        @media(max-width: $tablet-h) {
            font-size: 1.5rem;
        }
    }
    &__text {
        width: 100%;
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 1.3;
        margin: 0;
        @media(max-width: $tablet-h) {
            font-size: 1.25rem;
        }
    }
    &__year {
        display: block;
        width: 100%;
        text-align: center;
        font-family: $font-akrobat;
        font-weight: 300;
        color: $color-white;
        font-size: 11.25rem;
        line-height: 0.8;
        margin-top: 4rem;
        position: relative;
        z-index: 1;
        @media(max-width: $tablet-h) {
            margin-top: 1rem;
            font-size: 8rem;
        }
        @media(max-width: $tablet-v) {            
            font-size: 6.5rem;
        }
        @media(max-width: $phone-v) {
            font-size: 5rem;            
        }
    }
    &__label {
        display: block;
        width: 100%;
        text-align: center;
        font-family: $font-akrobat;
        font-size: 4rem;
        font-weight: 300;
        line-height: 1;
        color: $color-white;
        position: relative;
        z-index: 1;
        @media(max-width: $tablet-h) {
            font-size: 3rem;
        }
        @media(max-width: $phone-v) {
            font-size: 2rem;
        }
    }
}