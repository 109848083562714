.page-header {
    background-size: cover;
    background-position: center center;
    background-color: $color-accent;
    padding: 0.625rem 0;
    margin-bottom: 3.75rem;
    &--transparent {
        background-color: transparent;        
    }    
    @media(max-width: $tablet-h) {
        margin-bottom: 3.25rem;
    }
    @media(max-width: $phone-h) {
        margin-bottom: 2.5rem;
    }
    &--no-space-bottom {
        margin-bottom: 0;
    }
    &__title {
        font-size: 3.875rem;
        font-family: $font-akrobat;
        margin: 0 0 0.625rem 0;
        color: $color-white;
        text-transform: uppercase;
        @media(max-width: $tablet-h) {
            font-size: 3rem;
        }
        @media(max-width: $phone-h) {
            margin-bottom: 0;
            font-size: 2.5rem;
        }
        @media(max-width: $phone-s) {
            font-size: 2rem;
        }
    }
    &__flex {        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        & .select {
            margin-bottom: 0.625rem;
        }
    }
}
/* breadcrumbs */
.breadcrumbs {    
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    color: $color-white;
    &--dark {
        color: $color-black;
    }
    &__item {
        margin-right: 0.25rem;
        display: flex;
        align-items: center;
        &:after {
            content: '/';
            font-size: 0.875rem;        
            color: currentColor;
            opacity: 0.7;
            margin-left: 0.25rem;
        }
        &:last-child {
            margin-left: auto;
            margin-right: 0;
            &:after {
                display: none;
            }
        }
        &:nth-last-child(2):after {
            display: none;
        }
    }
    &__link {
        display: block;
        text-decoration: none;
        font-size: 0.875rem;        
        color: currentColor;
        opacity: 0.7;
        transition: opacity $transition-fast;
        .non-touch &:hover {
            opacity: 1;
        }
    }
    &__back-btn {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: currentColor;
        font-size: 0.875rem;
        transition: opacity $transition-fast;
        &:before {
            content: '';
            display: block;
            flex-shrink: 0;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-left: 0;
            border-right-color: currentColor;
            margin-right: 0.375rem;            
            transition: transform $transition-fast;
            .breadcrumbs--dark & {
                opacity: 0.5;
            }
        }
        .non-touch &:hover:before {
            transform: translateX(-2px);            
        }
        .non-touch &:hover {
            opacity: 0.7;
        }
    }
    @media(max-width: $phone-h) {
        display: none;
    }
}
/* end of breadcrumbs */