.btn {
    display: inline-flex;
    justify-content: center;
    text-decoration: none;
    font-family: $font-akrobat;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: 700;
    color: $color-white;
    background-color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 2px;
    padding: 0.875rem 1.125rem;
    cursor: pointer;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;        
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
        background-color: lighten($color-primary, 3%);
        transform: translate3d(0,100%,0);
        transform-origin: bottom center;
        opacity: 0;
        transition: transform $transition-fast, opacity $transition;
    }
    .non-touch &:hover:after {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
    .non-touch &:active {
        transform: translateY(1px);
    }
    &--secondary {
        border-color: $color-secondary;  
        background-color: $color-secondary;
        &:after {
            background-color: lighten($color-secondary, 3%);
        }
    }
    &--accent {
        border-color: $color-accent;      
        background-color: $color-accent;  
        &:after {
            background-color: lighten($color-accent, 3%);
        }
    }
    &--outline {        
        background-color: transparent;
        color: $color-primary;
        .non-touch &:hover {
            color: $color-white;
        }
    }
    &--outline.btn--secondary {
        color: $color-secondary;
    }
    &--outline.btn--accent {
        color: $color-accent;
    }
    &--transparent {
        background-color: transparent;
        border-color: transparent;
        color: $color-primary;        
        .non-touch &:hover:after {
            opacity: 0.1;
        }
    }
    &--transparent.btn--secondary {
        color: $color-secondary;
    }
    &--transparent.btn--accent {
        color: $color-accent;
    }
    &--black-text.btn {
        color: $color-black;
        transition: color $transition-fast;
        .non-touch &:hover {
            color: $color-white;
        }
    }
    &--white-text.btn {
        color: $color-white;
        transition: color $transition-fast;
        .non-touch &:hover {
            color: $color-white;
        }
    }
    &--min-width {
        min-width: 12.5rem;
    }
}

.btn-row {
    display: flex;    
    margin: -1rem;
    flex-wrap: wrap;
    &--center {
        justify-content: center;
    }
    &--align-right {
        justify-content: flex-end;
    }
    & .btn {
        margin: 1rem;
    }
    &--small-spacing  {
        margin: -0.5rem;
        .btn {
            margin: 0.5rem;
        }
    }
}