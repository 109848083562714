/* colors */
$color-white: #ffffff;
$color-black: #171b1e;
$color-primary: #00b472;
$color-secondary: #ee7b0d;
$color-accent: #0062bc;
$color-accent2: #e95c5c;
$color-accent3: #972cd4;
$color-light: #f1f5f8;
$color-grey: #babdbe;

/* font-family */
$font-akrobat: 'Akrobat', 'Roboto', 'Arial', sans-serif;
$font-roboto: 'Roboto', 'Arial', sans-serif;

/* media queries vars */
$desktop: 1200px;
$tablet-h: 1024px;
$tablet-v: 768px;
$phone-h: 640px;
$phone-v: 480px;
$phone-s: 359px;

/* transitions */
$transition: 0.3s ease;
$transition-fast: 0.15s ease;
$transition-slow: 0.6s ease;