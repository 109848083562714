.text {    
    color: $color-black;
    max-width: 100%;
    font-size: 1.125rem;    
    &--white {
        color: $color-white;
    }
    &--big {
        font-size: 1.375rem;
        letter-spacing: 0.5px;
        @media(max-width: $tablet-h) {
            font-size: 1.25rem;
        }
        @media(max-width: $phone-h) {
            font-size: 1.125rem;
        }
    }
    &--bigger {
        font-size: 1.625rem;
        letter-spacing: 0.5px;
        @media(max-width: $tablet-h) {
            font-size: 1.375rem;
        }
        @media(max-width: $phone-h) {
            font-size: 1.125rem;
        }
    }
    &--small {
        font-size: 1rem;
    }
    &--smaller {
        font-size: 0.875rem;
    }
    &--lineheight-compact.text {
        p, li {
            line-height: 1.3;
        }
    }
    &--lineheight-spaced.text {
        p, li {
            line-height: 1.66;
        }
        li:before {
            top: 0.625em;
        }
    }
    &--normal-font-weight.text {
        p, li {            
            font-weight: 400;
        }
    }
    &--white {
        color: $color-white;
    }
    /* text-clearfix */
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
    /* end of clearfix */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 0.375em 0;
        color: currentColor;        
        font-weight: 400;
    }
    h1 {
        font-size: 2.375em;
    }
    h2 {
        font-size: 2.25em;
    }
    h3 {
        font-size: 2em;
    }
    h4 {
        font-size: 1.625em;
    }
    h5 {
        font-size: 1.25em;
    }
    h6 {
        font-size: 1em;
    }
    @media(max-width: $tablet-v) {
        h1 {
            font-size: 2.125em;
        }
        h2 {
            font-size: 2em;
        }
        h3 {
            font-size: 1.75em;
        }
        h4 {
            font-size: 1.5em;
        }
        h5 {
            font-size: 1.125em;
        }
    }
    @media(max-width: $phone-v) {
        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.75em;
        }
        h3 {
            font-size: 1.5em;
        }
        h4 {
            font-size: 1.25em;
        }
    }
    ul,
    ol {
        margin: 0 0 1.35em 0;
    }
    p {
        font-size: 1em;
        line-height: 1.55;
        margin: 0 0 1.35em 0;
        color: currentColor;
        font-weight: 300;
    }
    a:not(.btn) {
        color: $color-accent;
        transition: color $transition-fast;
        .non-touch &:hover {
            color: $color-primary;
        }
    }
    li {
        font-size: 1em;
        line-height: 1.55;
        font-weight: 300;
        color: currentColor;
        margin-bottom: 0.125em;
        padding-left: 1em;
        overflow: hidden;
        position: relative;
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0.5em;
            left: 0;
            width: 0.4375rem;
            height: 0.4375rem;
            background-color: $color-primary;
            border-radius: 50%;            
        }        
    }
    ol {         
        list-style: decimal;
        list-style-position: inside;
    }
    ol li {
        padding-left: 0;
    }
    ol li:before {
        display: none;
    }
    /* table */
    table {        
        margin-bottom: 1.35em;
        border-collapse: collapse;
        &.table-layout-fixed {            
            table-layout: fixed;
        }
        &.table-width-100 {
            width: 100%;
        }
        & td,
        & th {
            padding: 1.35em;
            font-size: 1em;
            border: 1px solid lighten($color-grey, 15%);
            text-align: left;
        }
        & th {
            font-weight: 700;
            font-size: 1.0625em; 
            background-color: lighten($color-grey, 20%);           
        }
        .table-icon-block {            
            display: flex;
            align-items: center;            
        }
    }
    /* end of table */
    /* floating images */
    img {
        margin-bottom: 1.35em;
        max-width: 100%;        
        .center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
    img[style*='float: left'] {
        margin: 0.5em 1.35em 1.35em 0;
        max-width: 50%;
    }
    img[style*='float: right'] {
        margin: 0.5em 0 1.35em 1.35em;
        max-width: 50%;
    }
    @media(max-width: $phone-v) {        
        img[style*='float: left'],
        img[style*='float: right'] {
            margin: 0 0em 1.35em 0;
            max-width: 100%;
            float: none!important;
        }
    }
    /* end of floating images */  
    /* blockqoute */
    blockquote {
        background: $color-primary;        
        color: $color-white;
        margin: 0 0 2em 0;
        padding: 3.75rem 5.625rem;
        font-size: 1.25em;
        line-height: 1.4;
        /*font-weight: 300;*/
        position: relative;
        &:before,
        &:after {
            content: '“';
            font-size: 4.875rem;
            position: absolute;
            top: 1.25rem;
            left: 3.5rem;
        }        
        &:after {
            top: auto;
            left: auto;
            bottom: 1.25rem;
            right: 3.5rem;
            transform: rotate(180deg);
        }
        &[style*='float: left'] {
            max-width: 50%;
            margin-right: 2em;
            margin-left: 0;
            margin-top: 0.375em;
            transform: translateX(-3.125rem);
        }
        &[style*='float: right'] {
            max-width: 50%;
            margin-left: 2em;
            margin-right: 0;
            margin-top: 0.375em;
            transform: translateX(3.125rem);
        }
        @media(max-width: $tablet-v) {
            padding: 2.5rem 3.75rem;
            font-size: 1.125em;            
            &:before {
                top: 0.5rem;
                left: 2rem;
                font-size: 3.75rem;
            }
            &:after {
                bottom: 0.5rem;
                right: 2rem;
                font-size: 3.75rem;
            }
        }
        @media(max-width: $desktop) {
            &[style*='float: left'],
            &[style*='float: right'] {
                transform: translateX(0);
            }
        }
        @media(max-width: $phone-h) {
            &[style*='float: left'],
            &[style*='float: right'] {
                margin-right: 0;
                margin-left: 0;
                max-width: none;
            }
        }
        @media(max-width: $phone-v) {
            padding: 2rem 3rem;
            font-size: 1em;            
            &:before {
                top: 0.375rem;
                left: 1.5rem;
                font-size: 3rem;
            }
            &:after {
                bottom: 0.375rem;
                right: 1.5rem;
                font-size: 3rem;
            }
        }                
    }    
    /* end of blockquote */
    /* color themes */
    &.text--color-alt blockquote,
    &.text--color-alt li:before {
        background-color: $color-secondary;
    }
    .theme-primary & blockquote,
    .theme-primary & li:before,
    .theme-primary &.text--color-alt blockquote,
    .theme-primary &.text--color-alt li:before {
        background-color: $color-primary;
    }
    .theme-secondary & blockquote,
    .theme-secondary & li:before,
    .theme-secondary &.text--color-alt blockquote,
    .theme-secondary &.text--color-alt li:before {
        background-color: $color-secondary;
    }
    .theme-accent & blockquote,
    .theme-accent & li:before,
    .theme-accent &.text--color-alt blockquote,
    .theme-accent &.text--color-alt li:before {
        background-color: $color-accent;
    }
    .theme-accent2 & blockquote,
    .theme-accent2 & li:before,
    .theme-accent2 &.text--color-alt blockquote,
    .theme-accent2 &.text--color-alt li:before {
        background-color: $color-accent2;
    }
    .theme-accent3 & blockquote,
    .theme-accent3 & li:before,
    .theme-accent3 &.text--color-alt blockquote,
    .theme-accent3 &.text--color-alt li:before {
        background-color: $color-accent3;
    }
}
.text > :last-child {    
    margin-bottom: 0;
}
/* video embed */
.video-embed-wrapper {
	width: 50%;
	margin: 0.5em auto 1.35em auto;
    &--left {
        float: left;
        margin-right: 1.35em;
    }
    &--right {
        float: right;
        margin-left: 1.35em;
    }
    &--full {
    	width: 100%;       
    }
    &--full-padded {
    	width: 100%;
        padding: 0 8.5%;
        @media(max-width: $tablet-v) {
            padding: 0;
        }
    }
    @media(max-width: $phone-v) {
        width: 100%;
    }
}

.video-embed {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	margin: 0 auto;
    iframe {
    	position: absolute;
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
    }
}