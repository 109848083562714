.tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: lighten($color-grey, 20%);
    padding-top: 2.125rem;
    @media(max-width: $tablet-v) {
        padding-bottom: 2.125rem;
        background-color: transparent;        
    }
    &__btn {        
        display: flex;        
        align-items: center;
        justify-content: center;
        padding: 1rem 1.25rem;
        text-align: center;
        background-color: lighten($color-grey, 10%);
        color: $color-black;
        text-decoration: none;
        font-family: $font-akrobat;
        font-size: 1rem;
        line-height: 1.125;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin: 0 0.3125rem;
        &--min-width {
            min-width: 14.375rem;
        }
        &.is-active {            
            color: $color-black;
            background-color: $color-white;           
        }
        @media(max-width: $tablet-v) {
            width: 100%;
            padding: 1rem 1.875rem;
            border-bottom: 1px solid lighten($color-grey, 10%);
            justify-content: space-between;
            margin: 0;
            position: relative;
            &:after {
                content: '';
                width: 0.75rem;
                height: 0.75rem;                
                margin-left: 1rem;
                transform: rotate(45deg) translate(-2px, -2px);
                border-bottom: 2px solid $color-black;
                border-right: 2px solid $color-black;
            }
            &.is-active:after {
                display: none;
            }
        }        
        @media(max-width: $phone-v) {
            padding: 1 1.25rem;
        }
    }
    &__content {
        background-color: $color-white;        
        display: none;
        width: 100%;
        order: 1;
        padding-top: 3.75rem;
        @media(max-width: $tablet-v) {
            padding-top: 2rem;
        }
        @media(max-width: $tablet-v) {
            padding-top: 1rem;
            order: 0;
        }
        &.is-active {
            display: block;            
        }
    }    
}