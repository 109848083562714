.accordion {
    &__item {
        border: 1px solid lighten($color-grey, 10%);
        background-color: lighten($color-grey, 23%);;        
        margin-bottom: 1.25rem;
        &:last-child {
            margin-bottom: 0;
        }
        transition: border-color $transition;
        .non-touch &:hover {
            border-color: $color-grey;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: $color-black;
        font-weight: 400;
        letter-spacing: 0.44px;
        font-size: 1.375rem;
        padding: 1.625rem 2.5rem;        
        &:after {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            flex-shrink: 0;
            margin-left: 2.5rem;
            border-bottom: 2px solid $color-primary;
            border-right: 2px solid $color-primary;
            transform: rotate(45deg) translate(-0.125rem, -0.125rem);
            transition: border-color $transition, transform $transition;            
        }
        &.is-open:after {
            transform: rotate(225deg) translate(-0.125rem, -0.125rem);
        }
        .non-touch &:hover:after {
            border-color: $color-accent;
        }
        @media(max-width: $tablet-v) {
            font-size: 1.25rem;
            padding: 1.25rem 2rem;
            &:after {
                margin-left: 2rem;
            }
        }
        @media(max-width: $phone-v) {            
            padding: 1rem 1.25rem;
            &:after {
                margin-left: 1.25rem;
            }
        }
    }
    &__text {
        padding: 0.625rem 5rem 2.5rem 5rem;
        @media(max-width: $tablet-v) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
        @media(max-width: $phone-v) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }
}