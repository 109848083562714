.logo-row {
    background-color: $color-light;
    &__container {
        position: relative;        
        padding: 0 4rem;
    }
    &__slider-item {
        &:not(:first-child) {            
        	opacity: 0;
        	position: absolute;
        }
        .slick-initialized &:not(:first-child) {
        	opacity: 1;
        	position: static;
        }
    }
    &__slider-btn {
        width: 3rem;
        height: 3rem;    
        padding: 0;
        position: absolute;
        z-index: 1;
        top: 50%;
        margin-top: -1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        &:after {
            content: '';
            display: block;        
            width: 1.75rem;
            height: 1.75rem;
            border-top: 2px solid $color-primary;
            border-right: 2px solid $color-primary;
            transition: border-color $transition-fast;
        }
        &--prev {        
            left: -4.5rem;
            &:after {
                transform: rotate(-135deg) translate(-0.25rem, 0.25rem);
            }
        }
        &--next {        
            right: -4.5rem;
            &:after {            
                transform: rotate(45deg) translate(-0.25rem, 0.25rem);
            }
        }
        .non-touch &:hover:after {
            border-color: $color-accent;
        } 
    }
}