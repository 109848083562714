@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto-light-webfont.woff') format('woff'),
         url('../fonts/roboto-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/akrobat-bold-webfont.woff2') format('woff2'),
         url('../fonts/akrobat-bold-webfont.woff') format('woff'),
         url('../fonts/akrobat-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/akrobat-regular-webfont.woff2') format('woff2'),
         url('../fonts/akrobat-regular-webfont.woff') format('woff'),
         url('../fonts/akrobat-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/akrobat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/akrobat-semibold-webfont.woff') format('woff'),
         url('../fonts/akrobat-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Akrobat';
    src: url('../fonts/akrobat-light-webfont.woff2') format('woff2'),
         url('../fonts/akrobat-light-webfont.woff') format('woff'),
         url('../fonts/akrobat-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}