.events-list {    
    display: flex;
    flex-wrap: wrap;
    margin: -1.25rem;
    &__item {        
        padding: 1.25rem;
        width: 33.33%;                
    }    
    @media(max-width: $tablet-h) {        
        &__item {                
            width: 50%;
        }
    }
    @media(max-width: $tablet-v) {
        margin: -1rem;
        &__item {
            padding: 1rem;            
        }
    }
    @media(max-width: $phone-v) {
        &__item {
            width: 100%;
        }
    }
}
.event {
    display: block;
    background-color: $color-light;
    text-decoration: none;    
    color: lighten($color-black, 15%);
    &--theme-primary {
        color: $color-primary;
    }
    &--theme-secondary {
        color: $color-secondary;
    }
    &--theme-accent {
        color: $color-accent;
    }
    &--theme-accent2 {
        color: $color-accent2;
    }
    &--theme-accent3 {
        color: $color-accent3;
    }
    &__cover {        
        position: relative;
        overflow: hidden;
        &-image {
            display: block;
            width: 100%;
            height: auto;
            transition: transform $transition, opacity $transition;
            .non-touch & {
                transform: scale(1.1);
            }
        }
    }
    &__time {
        &--absolute {
            position: absolute;
            top: 0;
            left: 0;
        }
        display: inline-block;
        background-color: currentColor;
        padding: 0.75rem 0.875rem;
        &--bigger {
            padding: 1.375rem 1.625rem;
            flex-shrink: 0;
            margin-right: 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: center;        
            align-self: stretch;
            @media(max-width: $phone-h) {
                display: flex;
                padding: 0.75rem 0.875rem;
                margin-right: 0;
            }
        }
        &-text {
            display: block;            
            color: $color-white;
            font-family: $font-akrobat;
            font-size: 1.125rem;
            font-weight: 700;
            letter-spacing: 0.36px;
            line-height: 1.1;
            .event__time--bigger & {
                font-size: 1.375rem; 
                line-height: 1.15;                
                @media(max-width: $phone-h) {
                    & {
                        font-size: 1.125rem;
                    }
                }
            }
        }
        
    }
    &__info {
        padding: 1.625rem;
        @media(max-width: $tablet-h) {
            padding: 1.25rem;
        }
        &-list {            
            margin-bottom: 1rem;
            &--bigger {                
                display: flex;   
                margin: 0.25rem 0.25rem 0.25rem auto;
                flex-grow: 1;
                flex-wrap: wrap;
            }
            &-item {                
                display: flex;
                margin-bottom: 1rem;                
                &:last-child {
                    margin-bottom: 0;
                }
                .event__info-list--bigger & {
                    margin: 0.5rem;
                    align-items: center;
                    flex-shrink: 0;
                    max-width: 20rem;
                    @media(max-width: $tablet-h) {
                        max-width: none;
                        flex-shrink: 1;
                    }
                }
            }            
        }
        &-text {
            display: block;
            flex-shrink: 1;            
            font-family: $font-akrobat;
            font-weight: 700;
            color: $color-black;
            font-size: 1.0625rem;
            letter-spacing: 0.5px;
            line-height: 1.375rem;
            .event__info-list--bigger & {
                font-size: 1.125rem;
                font-weight: 400;
            }
        }
        &-icon {
            flex-shrink: 0;
            display: block;            
            width: 1.375rem;
            height: 1.375rem;            
            fill: currentColor;
            margin-right: 0.875rem;
            &--small {                
                width: 1.125rem;                
                margin: 0 1rem 0 0.125rem;
            }
            .event__info-list--bigger & {
                width: 1.5rem;
                height: 1.5rem;                
            }            
        }
        &--article {
            display: flex;            
            /*flex-wrap: wrap;*/
            align-items: center;
            position: relative;
            padding: 0;
            background-color: $color-light;
            /*color: $color-secondary;*/
            color: currentColor;
            margin-bottom: 2rem;
            & .event__title {
                color: $color-black;
                margin: 1rem 2.25rem 1rem 1rem;
            }
            @media(max-width: $tablet-h) {
                flex-wrap: wrap;
            }
            @media(max-width: $phone-h) {
                & .event__title {
                    font-size: 1.5rem;
                    margin: 0.75rem;                    
                }
            }
        }
        &-block {            
            display: flex;
            align-items: center;
            flex-grow: 1;
            align-self: stretch;
        }
    }
    &__title {
        display: block;
        font-family: $font-akrobat;
        letter-spacing: 0.56px;
        font-size: 1.75rem;
        line-height: 1.15;
        color: currentColor;
        font-weight: 400;        
        margin: 0 0 2rem 0;
        @media(max-width: $tablet-h) {
            margin-bottom: 1.5rem;
        }
    }
    .non-touch &:hover &__cover-image {
        transform: scale(1);
    }
    .non-touch &:hover .btn:after {
        transform: translateY(0);
        opacity: 0.1;
    }
}