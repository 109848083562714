.feature-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;    
    margin: -1.25rem 0;
    &--padded {
        padding: 0 5.625rem;
        @media(max-width: $desktop) {
            padding: 0;
        }
    }
    &__item {
        width: 30.3%;
        padding: 1.25rem;
        @media(max-width: $tablet-h) {
            width: 45%;
            &:last-child {
                margin: 0 auto;
            }
        }
        @media(max-width: $phone-h) {
            width: 100%;            
        }        
    }        
}
.feature  {
    display: flex;
    text-decoration: none;
    @media(max-width: $phone-v) {
        flex-wrap: wrap;
        justify-content: center;
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 6.25rem;
        height: 6.25rem;
        background-color: $color-primary;
        border-radius: 50%;
        margin-right: 1.875rem;        
        &-image {
            display: block;            
            width: 2.5rem;
            height: 2.5rem;
            fill: $color-white;
        }
        @media(max-width: $tablet-h) {
            width: 5rem;
            height: 5rem;
            margin-right: 1.5rem;
            &-image {
                width: 2rem;
                height: 2rem;
            }
        }
        @media(max-width: $phone-h) {
            width: 4rem;
            height: 4rem;
            margin-right: 1.25rem;
            &-image {
                width: 1.75rem;
                height: 1.75rem;
            }
        }
        @media(max-width: $phone-v) {
            margin-right: 0;
        }
    }
    &__textbox {
        padding-top: 1rem;
        flex-shrink: 1;
        @media(max-width: $tablet-h) {
            padding-top: 0.5rem;
        }
        @media(max-width: $phone-v) {
            width: 100%;
            text-align: center;            
        }
    }
    &__title {
        display: block;
        font-family: $font-akrobat;
        font-size: 1.875rem;        
        color: $color-black;
        margin-bottom: 0.625rem;
        @media(max-width: $phone-v) {            
            justify-content: center;
        }
        .non-touch .feature--link & {
            display: flex;
            align-items: center;
            transition: color $transition-fast;
        }
        .non-touch .feature--link &:after {
            content: '';
            display: inline-block;
            width: 0.625rem;
            height: 0.625rem;
            margin-left: 1rem;
            border-top: 2px solid $color-primary;
            border-right: 2px solid $color-primary;
            transform: rotate(45deg) translate(-1rem, 1rem);
            opacity: 0;
            transition: transform $transition, opacity $transition;
        }
        .non-touch .feature--link:hover & {
            color: $color-primary;
        }
        .non-touch .feature--link:hover &:after {
            opacity: 1;
            transform: rotate(45deg) translate(0rem, 0rem);
        }
    }
    &__text {
        display: block;
        color: $color-black;
        font-size: 1rem;
        line-height: 1.25;
        font-weight: 300;
        margin: 0;
    }
}
.feature-list--type-2 {    
    margin: -1.25rem;
    & .feature-list__item {
        width: 31%;        
        @media(max-width: $tablet-h) {
            width: 45%;
            &:last-child {
                margin: 0 auto;
            }
        }
        @media(max-width: $tablet-v) {
            width: 100%; 
        }
    }
    @media(max-width: $tablet-h) {
        margin: -1.25rem 0;
    }
    .feature {        
        flex-wrap: wrap;
        &__icon {
            border: 2px solid $color-primary;
            margin: 0 auto 1.5rem auto;
            background-color: transparent;
            &-image {
                fill: $color-black;
                width: 3.125rem;
                height: 3.125rem;
            }
            &--secondary {
                border-color: $color-secondary;
            }
            &--accent {
                border-color: $color-accent;
            }
            &--accent2 {
                border-color: $color-accent2;
            }
        }
        &__textbox {
            width: 100%;
            text-align: center;
        }
        &__title {
            font-size: 1.625rem;
            font-weight: 400;
            margin-bottom: 1.75rem;
        }
        &__text {
            font-size: 1.125rem;
            line-height: 1.45;
        }
        @media(max-width: $tablet-h) {
            &__icon {
                margin-bottom: 1rem;
                &-image {
                    fill: $color-black;
                    width: 2.25rem;
                    height: 2.25rem;
                }
            }
            &__title {
                margin-bottom: 1rem;
            }
        }
        @media(max-width: $phone-h) {
            &__icon {
                margin-bottom: 0.75rem;
                &-image {
                    fill: $color-black;
                    width: 1.75rem;
                    height: 1.75rem;
                }
            }
            &__title {
                margin-bottom: 0.75rem;
            }
        }
    }
}