.article {    
    max-width: 82.716049382716049%;    
    margin: 0 auto;
    color: $color-primary;
    &.theme-primary {
        color: $color-primary;
    }
    &.theme-secondary {
        color: $color-secondary;
    }
    &.theme-accent {
        color: $color-accent;
    }
    &.theme-accent2 {
        color: $color-accent2;
    }
    &.theme-accent3 {
        color: $color-accent3;
    }
    & blockquote {
        margin-left: -3.125rem;
        margin-right: -3.125rem;
        @media(max-width: $desktop) {
            margin-left: 0;
            margin-right: 0;
        }
    }    
    &__title {
        display: block;
        font-size: 3.75rem;
        font-weight: 600;
        font-family: $font-akrobat;
        color: $color-black;
        margin: 0 0 1rem 0;        
    }
    @media(max-width: $tablet-h) {
        max-width: 90%;
        &__title {
            font-size: 3.25rem;
        }
    }
    @media(max-width: $tablet-v) {
        max-width: none;
        &__title {
            font-size: 2.75rem;
        }
    }
    @media(max-width: $phone-h) {
        &__title {
            font-size: 2.25rem;
        }
    }
    &__time {
        display: block;
        font-size: 1.125rem;
        font-family: $font-akrobat;
        color: $color-black;
        font-weight: 700;
        letter-spacing: 0.36px;
        margin-bottom: 0.75rem;
    }
    
    &__pic {
        width: 100%;
        height: auto;        
    }
    &__cover {        
        position: relative;
        .article__controls {
            position: absolute;            
            background-color: darken($color-light, 2.5%);
            z-index: 1;
            bottom: 0;            
            right: 0;
            margin: 0;
            padding: 0.125rem;
            justify-content: flex-end;
            align-items: center;            
            @media(max-width: $phone-v) {
                position: static;
                justify-content: space-between;                
            }
        }
    }
    &__controls {        
        margin: 0.5rem -0.75rem 1.75rem -0.75rem;
        display: flex;
        justify-content: space-between;
        &-btn {            
            padding: 0.5rem;
            margin: 0.25rem;
            text-decoration: none;            
            display: flex;
            align-items: center;
            font-family: $font-akrobat;
            font-size: 1.125rem;
            font-weight: 700;
            letter-spacing: 0.36px;
            color: currentColor;
            &-text {
                color: $color-black;
            }
            &-icon {
                display: inline-block;
                flex-shrink: 0;
                width: 1.375rem;
                height: 1.375rem;
                fill: currentColor;                            
                margin-left: 0.6125rem;                
            }
            @media(max-width: $phone-s) {
                flex-basis: 0;
            }
        }
    }    
}